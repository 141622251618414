@import url('https://fonts.googleapis.com/css2?family=Lustria&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;700&display=swap'); // COLORS
$orange: #89300e;

$yellow_10: #9a6c22; // Secondary
$green_10: #878f4f;
$pink: #c49787;
$cream: #fff8ed;

html {
  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(69, 74, 92, 0.12) !important;
    border: 5px solid rgba(0, 0, 0, 0) !important;
    background-clip: padding-box !important;
  }
  scrollbar-width: thin;
  scrollbar-color: rgba(69, 74, 92, 0.12) rgba(0, 0, 0, 0);
}

body {
  margin: 0px;
  overflow: hidden;
  background: #ffffff;

  &.without-scrollbars {
    *.body-scrollbar {
      overflow: hidden !important;
    }
  }
}

p {
  font-family: 'Mulish';
}

.boxes {
  margin-left: 12px;
  width: 245px;
  height: 88px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-weight: bold;
  font-size: 0.9em;
  padding: 17px;
}
.generic {
  color: #666666;
  background-color: #fff;
}
.ytd {
  color: #fff;
  background-color: #6c6533;
}

.ytd_expenses {
  float: right;
  color: #8e6e59;
}

.total {
  width: 283px;
  margin-top: 10px;
  margin-right: 20px;
  float: right;
  border-left: 10px solid #ebc9c2;
  border-radius: 4px;
  background: radial-gradient(
      63.89% 80.99% at 60.55% 100%,
      rgba(179, 73, 117, 0.2) 0%,
      rgba(248, 245, 240, 0) 100%
    ),
    radial-gradient(
      50.35% 105.68% at 83.44% 91.74%,
      rgba(211, 108, 80, 0.25) 4.05%,
      rgba(248, 245, 240, 0) 100%
    ),
    radial-gradient(
      39.96% 126.3% at 42.89% 100%,
      rgba(255, 160, 0, 0.25) 0%,
      rgba(223, 177, 102, 0) 100%,
      rgba(248, 245, 240, 0) 100%
    ),
    linear-gradient(0deg, #f5f5f4, #f5f5f4), #ffffff;
}

.top-cards {
  font-family: 'Mulish';
  margin-top: 3px;
  color: $pink;
}

.items {
  font-size: 14px;
  margin-left: -12px;
}

.card-title {
  font-family: 'Lustria';
  color: #000;
  margin: 0px;
  padding-top: 27px;
  margin-left: 16px;
  font-size: 24px;
}

.property-list {
  margin: 0px;
  font-family: 'Mulish';
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  color: $yellow_10;
}
